<template>
  <WeContainer card="" seo-bar="" v-if="ready">
    <div>
      <Navigation
        v-on:save-form="submitForm"
        v-bind:submit="submit"
        v-bind:errors="errors"
      />
    </div>
    <div class="tab-content">
      <div class="tab-pane fade show active">
        <div class="row align-items-stretch mb-xl-3">
          <div class="col-12 mb-3 col-xl mb-xl-0">
            <div class="card h-100">
              <div class="card-body">
                <div class="form-group">
                  <div class="row">
                    <div class="col">
                      <label
                        for="member-type"
                        class="custom-label"
                        v-bind:class="{
                          'text-danger': $v.memberType.$error,
                        }"
                        >Üye Tipi</label
                      >
                    </div>
                    <div class="col-auto" v-if="$v.memberType.$error">
                      <div
                        class="text-danger ml-auto"
                        v-if="!$v.memberType.required"
                      >
                        <small><i>Zorunlu Alan</i></small>
                      </div>
                    </div>
                  </div>
                  <select
                    name="member_type"
                    class="custom-select"
                    :class="{
                      'border-danger': $v.memberType.$error,
                    }"
                    id="member-type"
                    v-bind:disabled="!membergroup.memberTypeList.length"
                    v-model="memberType"
                  >
                    <option value="-1">{{ getOptionText }}</option>
                    <option
                      v-for="type in membergroup.memberTypeList"
                      v-bind:key="type.id"
                      v-bind:value="type.id"
                    >
                      {{ type.name }}
                    </option>
                  </select>
                </div>

                <!-- Member Group Name -->
                <div class="form-group">
                  <WeInput
                    label="Üye Grup Adı"
                    placeholder="Üye Grup Adı"
                    name="member_group_name"
                    v-bind:required="true"
                    v-model="memberGroupName"
                    v-bind:error="$v.memberGroupName.$error"
                    v-on:keyup-enter="submitForm"
                  />
                </div>
                <!-- ./Member Group Name -->

                <!-- Member Open Account -->
                <div class="form-group">
                  <label class="custom-label">Açık Hesap Durumu</label>
                  <div class="custom-control custom-switch">
                    <input
                      type="checkbox"
                      class="custom-control-input"
                      id="open-account"
                      v-model="membergroup.new.is_open"
                    />
                    <label class="custom-control-label" for="open-account"
                      >Açık Hesap</label
                    >
                  </div>
                </div>
                <!-- ./Member Open Account -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </WeContainer>
  <WeLoading v-else />
</template>
<script>
import { mapState, mapMutations, mapActions } from "vuex";
import { required } from "vuelidate/lib/validators";
const Navigation = () => import("./views/Navigation/Index");

export default {
  name: "Detail",
  data() {
    return {
      ready: false,
      submit: false,
      errors: [],
    };
  },
  components: {
    Navigation,
  },
  validations: {
    memberGroupName: {
      required,
    },
    memberType: {
      required: function () {
        return this.memberType !== -1 && this.memberType !== "-1";
      },
    },
  },
  methods: {
    ...mapMutations("shared", ["setMessage", "clearMessage"]),
    ...mapActions("membergroup", ["load", "create", "update", "getById"]),
    validateForm() {
      this.$v.$touch();

      if (this.$v.$invalid) {
        this.$swal({
          title: "Hata",
          text: "Lütfen zorunlu alanları doldurun",
          icon: "error",
        });

        return false;
      } else {
        return true;
      }
    },
    submitForm() {
      if (!this.validateForm()) {
        return;
      }
      this.submit = true;

      if (this.$route.params.id) {
        this.updateMemberGroup();
      } else {
        this.addMemberGroup();
      }
    },
    addMemberGroup() {
      this.create({
        onSuccess: (result) => {
          if (result && result.data && result.data.status !== "error") {
            this.showAlert(result, this.redirectToList);
          } else {
            this.showAlert(result);
          }
        },
        onFinish: () => {
          this.submit = false;
        },
      });
    },
    updateMemberGroup() {
      this.update({
        id: this.$route.params.id,
        onSuccess: (result) => {
          if (result && result.data && result.data.status !== "error") {
            this.showAlert(result, this.redirectToList);
          } else {
            this.showAlert(result);
          }
        },
        onFinish: () => {
          this.submit = false;
        },
      });
    },
    getMemberGroupById() {
      if (this.$route.params.id) {
        this.getById({
          id: this.$route.params.id,
          onSuccess: (result) => {
            this.ready = true;
          },
        });
      } else {
        this.ready = true;
      }
    },
    redirectToList() {
      this.$router.push("/member-groups");
    },
    showAlert(result, callback) {
      this.setMessage(result);
      this.$swal(this.shared.message).then((confirm) => {
        if (callback && confirm.isConfirmed) callback();
      });
      this.clearMessage();
    },
  },
  computed: {
    ...mapState(["membergroup", "shared"]),
    getTitle() {
      return this.$route.params.id ? "ÜYE GRUP DÜZENLE" : "ÜYE GRUP EKLE";
    },
    checkPermission() {
      return permission.check("memberGroup", "u");
    },
    memberGroupName: {
      get() {
        return this.membergroup.new.name;
      },
      set(value) {
        this.membergroup.new.name = value;
      },
    },
    memberType: {
      get() {
        return this.membergroup.new.memberType;
      },
      set(value) {
        this.membergroup.new.memberType = value;
      },
    },
    getOptionText() {
      return !this.membergroup.memberTypeList.length
        ? "Mevcut Değil"
        : "Seçiniz";
    },
  },
  mounted() {
    this.load();
    this.getMemberGroupById();
  },
};
</script>
